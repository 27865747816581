import React from 'react';
import { graphql } from 'gatsby';
import { Seo } from '../components/layout/meta';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';
import { Card as IssueCard } from '../components/issues';
import { Default, Symposium } from '../components/issues/single';
import classNames from 'classnames';

export default function Issue({
  data: {
    wp: {
      siteOptionsMetaboxSettings: { optionsIssuesPageTitle: pageTitle },
    },
    wpTghpTaxonomyIssue: issue,
  },
}) {
  const issueComponents = {
    default: Default,
    symposium: Symposium,
  };

  const { tghpwipIssuesTaxonomyCategory } = issue;

  let Issue = issueComponents.default;
  let issueType = 'default';
  if (issueComponents[tghpwipIssuesTaxonomyCategory]) {
    Issue = issueComponents[tghpwipIssuesTaxonomyCategory];
    issueType = tghpwipIssuesTaxonomyCategory;
  }

  return (
    <>
      <Seo title={issue?.name} />
      <Layout location={'single-issue'} issueName={issue.name}>
        <div className={classNames(['single-issue-grid', `single-issue-grid--${issueType}`])}>
          <div className="single-issue-grid__image">
            <IssueCard issue={issue} />
          </div>
          <Issue pageTitle={pageTitle} issue={issue} />
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpTghpTaxonomyIssue(slug: { eq: $slug }) {
      ...AllIssues
    }

    wp {
      ...SiteOptions
    }
  }
`;

export const Head = () => <HeadMeta />;
