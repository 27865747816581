import React from 'react';
import { getGroupedIssuePosts } from '../../../model/post';
import { Intro, Posts } from '../symposium';

function Default({ issue }) {
  const {
    posts: { nodes: issuePosts },
  } = issue;

  const [spotlightPosts, responsePosts, conclusionPosts] = getGroupedIssuePosts(issuePosts);

  return (
    <div className="single-issue-grid__content">
      <Intro className="single-issue-grid__intro" issue={issue} />
      <Posts
        className="single-issue-grid"
        spotlightPosts={spotlightPosts}
        responsePosts={responsePosts}
        conclusionPosts={conclusionPosts}
      />
    </div>
  );
}

export default Default;
