import React from 'react';
import parse from 'html-react-parser';
import { Card as ArticleCard, Spotlight } from '../../articles/card';
import { getGroupedIssuePosts } from '../../../model/post';

function Default({ pageTitle, issue }) {
  const {
    tghpwipIssuesTaxonomyContent: content,
    posts: { nodes: issuePosts },
  } = issue;

  const [spotlightPosts, restPosts] = getGroupedIssuePosts(issuePosts);

  return (
    <>
      {content && (
        <div className="single-issue-grid__content">
          <h2 className="single-issue-grid__content-title">{pageTitle}</h2>
          <div className="single-issue-grid__content-text">{parse(content)}</div>
        </div>
      )}
      {!!spotlightPosts.length && (
        <div className="single-issue-grid__spotlight">
          <Spotlight post={spotlightPosts[0]} issue={issue} />
        </div>
      )}
      {!!restPosts.length && (
        <div className="single-issue-grid__posts">
          {restPosts.map((post) => (
            <ArticleCard post={post} key={post.slug} />
          ))}
        </div>
      )}
    </>
  );
}

export default Default;
